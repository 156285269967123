var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-2",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
      ratio: 1.5,
      minFontSize: '10px',
      maxFontSize: '14px',
      delay: 100,
    }),expression:"{\n      ratio: 1.5,\n      minFontSize: '10px',\n      maxFontSize: '14px',\n      delay: 100,\n    }"}],staticClass:"text-no-wrap mr-2",attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.vote.choice[_vm.choice].name)+" : ")]),_c('v-col',{attrs:{"cols":"undefined"}},[(_vm.vote.choice)?_c('v-progress-linear',{attrs:{"color":_vm.color,"label":_vm.vote.choice[_vm.choice].name,"height":"5","rounded":"","value":Math.round((_vm.vote.choice[_vm.choice].score / _vm.vote.nbVoters) * 100) / 100}}):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-end text-no-wrap caption",staticStyle:{"max-width":"60px"},attrs:{"cols":"3"}},[(_vm.vote.choice && _vm.vote.nbVoters != 0)?_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 1.5,
        minFontSize: '10px',
        maxFontSize: '14px',
        delay: 100,
      }),expression:"{\n        ratio: 1.5,\n        minFontSize: '10px',\n        maxFontSize: '14px',\n        delay: 100,\n      }"}]},[_vm._v(" "+_vm._s(Math.round((_vm.vote.choice[_vm.choice].score / _vm.vote.nbVoters) * 100) / 100)+" % ")]):_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 1.5,
        minFontSize: '10px',
        maxFontSize: '14px',
        delay: 100,
      }),expression:"{\n        ratio: 1.5,\n        minFontSize: '10px',\n        maxFontSize: '14px',\n        delay: 100,\n      }"}]},[_vm._v("  0.00 % ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }