<template>
  <v-container fluid class="ml-1 px-2 py-0">
    <v-row no-gutters justify="center" align="start">
      <v-btn text plain icon small @click="upVoting(vote)" :disabled="shouldDisable()">
        <svg-icon
          v-if="isRankUp(vote.id)"
          small
          color="yellow"
          type="mdi"
          :path="rankUpPath"
        ></svg-icon>
        <svg-icon v-else small type="mdi" :path="rankUpPath"></svg-icon>
      </v-btn>
    </v-row>
    <v-row no-gutters justify="center">
      <span>{{ vote.rank }}</span>
    </v-row>
    <v-row no-gutters justify="center">
      <v-btn text plain icon small @click="downVoting(vote)" :disabled="shouldDisable()">
        <svg-icon
          v-if="isRankDown(vote.id)"
          small
          color="yellow"
          type="mdi"
          :path="rankDownPath"
        ></svg-icon>
        <svg-icon v-else small type="mdi" :path="rankDownPath"></svg-icon>
      </v-btn>
    </v-row>
    <v-row no-gutters justify="center">
      <v-btn text plain icon small @click="pin(vote.id, index)" :disabled="shouldDisable()">
        <svg-icon
          v-if="isPin(vote.id)"
          small
          color="yellow"
          type="mdi"
          :path="pinPath"
        ></svg-icon>
        <svg-icon v-else small type="mdi" :path="pinPath" size="16"></svg-icon>
      </v-btn>
    </v-row>
    <v-row no-gutters justify="center">
      <v-btn text plain icon small :disabled="shouldDisable()">
        <svg-icon small type="mdi" :path="eyeOffPath" size="16"></svg-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import Vuex from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiMenuUp, mdiEyeOff, mdiPin, mdiMenuDown } from "@mdi/js";

export default {
  name: "VoteActionLeftCard",
  props: ["vote", "index"],
  components: {
    SvgIcon,
  },
  data: () => ({
    rankUpPath: mdiMenuUp,
    eyeOffPath: mdiEyeOff,
    pinPath: mdiPin,
    rankDownPath: mdiMenuDown,
  }),
  computed: {
    ...Vuex.mapState("user", ["isAuthenticatedUser"]),
  },
  methods: {
    shouldDisable: function() {      
      return this.$store.state.route.name == "worldCountryThemeVote" ||
      this.$store.state.route.name == "worldThemeVote" ||
      this.$store.state.route.name == "worldCountryZoneThemeVote" ||
      this.$store.state.route.name == "worldZoneThemeVote" ||
      !this.isAuthenticatedUser
    },
    pin: async function (id, index) {
      await this.$store.dispatch("user/pin", { voteId: id, index: index });
      this.isPin(id);
    },
    isPin(id) {
      if (!(this.$store.state.user.userProfile.pin === undefined)) {
        return this.$store.state.user.userProfile.pin.indexOf(id) > -1
          ? true
          : false;
      } else return false;
    },
    isRankUp(id) {
      if (!(this.$store.state.user.userProfile.rankUp === undefined)) {
        return this.$store.state.user.userProfile.rankUp.indexOf(id) > -1
          ? true
          : false;
      } else return false;
    },
    isRankDown(id) {
      if (!(this.$store.state.user.userProfile.rankDown === undefined)) {
        return this.$store.state.user.userProfile.rankDown.indexOf(id) > -1
          ? true
          : false;
      } else return false;
    },
    upVoting: function (vote) {
      if (
        this.isRankDown(vote.id) ||
        (!this.isRankDown(vote.id) && !this.isRankUp(vote.id))
      )
        this.vote.rank = vote.rank + 1;
      return this.$store.dispatch("user/upVoting", vote);
    },
    downVoting: function (vote) {
      if (
        this.isRankUp(vote.id) ||
        (!this.isRankDown(vote.id) && !this.isRankUp(vote.id))
      )
        this.vote.rank = vote.rank - 1;
      return this.$store.dispatch("user/downVoting", vote);
    },
  },
};
</script>
