<template>
  <v-card-text class="pa-0 mb-1">
    <div class="">
      <v-btn
        v-for="(cat, index) in voteCategory"
        :key="cat.id"
        x-small
        tile
        class="ml-1 px-1 text-lowercase"
        dark
        >{{ cat ? index : "" }}</v-btn
      >
      <v-btn
        x-small
        tile
        class="ml-1 px-1 text-lowercase"
        dark
        :href="voteSource"
        target="_blank"
        >source</v-btn
      >
    </div>
  </v-card-text>
</template>

<script>
export default {
  name: "VoteListCategory",
  props: ["voteCategory", "voteSource"],
};
</script>
