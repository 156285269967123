<template>
  <v-row align="center" no-gutters :id="wrapperId">
    <v-col
      cols="12"
      class="text-no-wrap mr-2"
      v-resize-text="{
        ratio: 1.5,
        minFontSize: '10px',
        maxFontSize: '14px',
        delay: 100,
      }"
    >
      mon vote {{ vote.choice[choice].name }} :
      {{ myVote.one == 0 && myVote.two == 0 ? "Vote blanc" : "" }}
    </v-col>

    <v-col cols="undefined" :id="wrapperId + '2'">
      <v-slider
        hide-details
        :id="sliderId"
        v-model="myVote[choice]"
        :color="
          'rgba(' +
          (255 - Math.round((r / 100) * myVote[choice])) +
          ',' +
          (255 - Math.round((g / 100) * myVote[choice])) +
          ',' +
          (255 - Math.round((b / 100) * myVote[choice])) +
          ',1)'
        "
        min="0"
        max="100"
        dense
      >
      </v-slider>
    </v-col>
    <v-col
      fill-height
      cols="3"
      style="max-width: 60px; height: 39px"
      class="d-flex align-start justify-end text-no-wrap caption"
    >
      <div
        v-if="vote.choice"
        class=""
        v-resize-text="{
          ratio: 1.5,
          minFontSize: '10px',
          maxFontSize: '14px',
          delay: 100,
        }"
      >
        {{ myVote[choice] }}.00 %
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MyChoiceSlide",
  props: ["vote", "choice", "wrapperId", "myVote", "sliderId", "r", "g", "b"],
};
</script>
