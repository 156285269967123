<template>
  <v-container fluid class="pt-0">
    <v-card
      class="mx-auto mb-2 d-flex align-stretch flex-column"
      :key="vote.id"
    >
      <v-row no-gutters align="start" justify="start" class="pt-1">
        <v-col
          cols="1"
          class="d-flex flex-column caption font-weight-light justify-end"
        >
          <VoteActionLeftCard :vote="vote" :index="vote.id" />
        </v-col>

        <v-col cols="11" class="pl-1 mt-1">
          <v-card-title primary-title class="pa-0 subtitle-2 mb-auto">
            <v-col cols="12" class="pl-1">
              <v-row>
                <v-col cols="9" class="pt-0" style="padding-right: 0px">
                  <h3
                    style="
                      word-break: normal;
                      line-height: 1.2;
                      padding-top: 8px;
                    "
                    v-resize-text="{
                      ratio: 1.5,
                      minFontSize: '12px',
                      maxFontSize: '16px',
                      delay: 100,
                    }"
                  >
                    {{ vote.name | capitalize }}
                  </h3>
                </v-col>
                <v-col class="pt-0">
                  <VoteMedal :medals="vote.medal" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0" style="padding-right: 18px">
                  <h6
                    style="word-break: break-word; line-height: 1.2"
                    v-resize-text="{
                      ratio: 1.5,
                      minFontSize: '8px',
                      maxFontSize: '10px',
                      delay: 100,
                    }"
                  >
                    {{ vote.description | capitalize }}
                  </h6>
                </v-col>
              </v-row>

              <VoteLineBarChoiceResult :vote="vote" choice="one" color="blue" />
              <MyChoiceSlider
                wrapperId="myVoteOneWrapper"
                sliderId="myVoteOne"
                :vote="vote"
                :myVote="myVote"
                choice="one"
                r="222"
                g="105"
                b="12"
              />

              <VoteLineBarChoiceResult :vote="vote" choice="two" color="red" />
              <MyChoiceSlider
                wrapperId="myVoteTwoWrapper"
                sliderId="myVoteTwo"
                :vote="vote"
                :myVote="myVote"
                choice="two"
                r="11"
                g="188"
                b="201"
              />
            </v-col>
          </v-card-title>
          <VoteListCategory
            :voteCategory="vote.category"
            :voteSource="vote.url"
          />
        </v-col>
      </v-row>
      <v-row align="center" justify="end" class="mt-0">
        <v-card-actions class="justify-end mb-2 mr-2 pt-0">
          <v-spacer></v-spacer>

          <v-btn icon>
            <!-- <v-icon>mdi-chart-multiline</v-icon> -->
            <svg-icon
              type="mdi"
              :path="chartMultilinePath"
              size="16"
            ></svg-icon>
          </v-btn>

          <v-btn
            color="success"
            depressed
            :loading="loading"
            :disabled="loading"
            fab
            class="mr-2 mb-2 vote-btn"
            large
            dark
            @click="aVote()"
          >
            <!-- <v-icon>mdi-vote</v-icon> -->
            <svg-icon type="mdi" :path="votePath" size="16"></svg-icon>
            <span class="btn-text">{{ voteStatus }}</span>
          </v-btn>

          <v-dialog v-model="dialog" max-width="290">
            <v-card id="voteNotChanged">
              <v-card-title class="headline"> </v-card-title>
              <v-card-text>
                Votre vote n'a pas changé.<br />
                Voulez-vous confirmer votre vote ?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="dialog = false">
                  Non
                </v-btn>
                <v-btn color="green darken-1" text @click="aVoteConfirm()">
                  Oui
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogNotConnected" max-width="290">
            <v-card id="userNotConnected">
              <v-card-title class="headline"> </v-card-title>
              <v-card-text>
                Pour voter, il suffit de présenter votre carte d'électeur
                Demokratio.<br />
                ou d'en créer une. C'est gratuit.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  text
                  @click="dialogNotConnected = false"
                >
                  Non
                </v-btn>
                <v-btn color="green darken-1" text @click="redirectToLogin()">
                  Oui
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-row>

      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        x-small
        bottom
        right
        color="black"
        @click="toTop"
      >
        <!-- <v-icon>mdi-chevron-up</v-icon> -->
        <svg-icon type="mdi" :path="chevronUpPath" size="16"></svg-icon>
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
import Vuex from "vuex";
import VoteActionLeftCard from "@/component/user/ui/VoteActionLeftCard.vue";
import VoteLineBarChoiceResult from "@/component/vote/ui/VoteLineBarChoiceResult.vue";
import MyChoiceSlider from "@/component/user/ui/MyChoiceSlider.vue";
import VoteMedal from "@/component/vote/voteItem/ui/VoteMedal.vue";
import VoteListCategory from "@/component/vote/voteItem/ui/VoteListCategory.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChartMultiline, mdiVote, mdiChevronUp, mdiMedal } from "@mdi/js";

export default {
  name: "VoteItem",
  components: {
    VoteActionLeftCard,
    VoteLineBarChoiceResult,
    MyChoiceSlider,
    VoteMedal,
    VoteListCategory,
    SvgIcon,
  },
  data: () => ({
    fab: false,
    loader: null,
    loading: false,
    voteStatus: "vote",
    initialMyVote: {
      one: 0,
      two: 0,
    },
    dialog: false,
    dialogNotConnected: false,
    chevronUpPath: mdiChevronUp,
    votePath: mdiVote,
    chartMultilinePath: mdiChartMultiline,
    medalPath: mdiMedal,
  }),
  methods: {
    redirectToLogin() {
      sessionStorage.setItem("redirectPath", this.$router.history.current.path);
      this.$router.push({
        name: "Login",
      });
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    star: function (id) {
      alert(id);
    },
    aVote: async function () {
      if (this.isAuthenticatedUser) {
        if (
          this.myVote.one == this.initialMyVote.one &&
          this.myVote.two == this.initialMyVote.two &&
          this.myVote.one > 0 &&
          this.myVote.two > 0
        ) {
          this.dialog = true;
        } else {
          this.aVoteConfirm();
        }
      } else {
        this.dialogNotConnected = true;
      }
    },
    aVoteConfirm: async function () {
      this.dialog = false;
      this.loader = "loading";
      this.loading = true;
      await this.$store.dispatch("vote/aVote", {
        myVote: this.myVote,
        prev: this.initialMyVote,
      });
      this.loading = false;
      this.voteStatus = "a voté";

      //   setTimeout( () => this.$router.push({ name: this.$store.state.route.from.name, params: this.$store.state.route.from.params}), 2000);
      // setTimeout(() => history.back(), 1000);
      // quand on se reconnecte au retouve la page liste de votes et non la page login
      setTimeout(() => {
        if (
          this.$store.state.route.from.name == "Login" ||
          this.$store.state.route.from.name == "SignUp"
        ) {
          this.$router.go(-3);
        } else {
          this.$router.go(-1);
        }
      }, 1000);
    },
  },
  computed: {
    ...Vuex.mapGetters("vote", [
      "myVote",
      "vote",
      "electedCriteria",
      "electedPour",
      "electedEcart",
      "electedMajorite",
    ]),
    ...Vuex.mapState("user", ["isAuthenticatedUser"]),
  },
  created: async function () {
    await this.$store.dispatch("vote/getMyVote");
    if (this.myVote.one > 0 && this.myVote.two > 0) this.voteStatus = "reVote";
    this.initialMyVote = Object.assign({}, this.myVote);
  },
  mounted: async function () {
    this.$store.getters.breadcrumb;
  },
};
</script>

<style>
.vote-btn .v-btn__content {
  flex-direction: column;
}
.vote-btn span.btn-text {
  font-size: 0.6em;
}
</style>
