<template>
  <div class="vote">
    <template>
      <v-container pa-0>
        <VoteItem />
      </v-container>
    </template>
  </div>
</template>

<script>
import VoteItem from "@/component/vote/voteItem/VoteItem.vue";

export default {
  name: "Vote",
  data: () => ({}),
  components: {
    VoteItem,
  },
};
</script>
