<template>
  <v-row no-gutters justify="end" class="pt-2">
    <svg-icon
      v-show="medals.majoritaire"
      small
      color="blue"
      type="mdi"
      :path="medalPath"
      size="16"
    ></svg-icon>
    <svg-icon
      v-show="medals.pour"
      small
      color="orange"
      type="mdi"
      :path="medalPath"
      size="16"
    ></svg-icon>
    <svg-icon
      v-show="medals.federateur"
      small
      color="purple"
      type="mdi"
      :path="medalPath"
      size="16"
    ></svg-icon>
  </v-row>
</template>

<script>
import Vuex from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiMedal } from "@mdi/js";
export default {
  name: "VoteListHeader",
  props: ["medals"],
  components: {
    SvgIcon,
  },
  data: () => ({
    medalPath: mdiMedal,
  }),
  computed: {
    ...Vuex.mapGetters("vote", [
      "electedPour",
      "electedEcart",
      "electedMajorite",
    ]),
  },
};
</script>
