<template>
  <v-row align="center" no-gutters class="mt-2">
    <v-col
      cols="12"
      class="text-no-wrap mr-2"
      v-resize-text="{
        ratio: 1.5,
        minFontSize: '10px',
        maxFontSize: '14px',
        delay: 100,
      }"
      >{{ vote.choice[choice].name }} :
    </v-col>
    <v-col cols="undefined">
      <v-progress-linear
        v-if="vote.choice"
        :color="color"
        :label="vote.choice[choice].name"
        height="5"
        rounded
        :value="
          Math.round((vote.choice[choice].score / vote.nbVoters) * 100) / 100
        "
      ></v-progress-linear>
    </v-col>
    <v-col
      cols="3"
      style="max-width: 60px"
      class="d-flex justify-end text-no-wrap caption"
    >
      <div
        v-if="vote.choice && vote.nbVoters != 0"
        class=""
        v-resize-text="{
          ratio: 1.5,
          minFontSize: '10px',
          maxFontSize: '14px',
          delay: 100,
        }"
      >
        {{
          Math.round((vote.choice[choice].score / vote.nbVoters) * 100) / 100
        }}
        %
      </div>
      <div
        v-else
        class=""
        v-resize-text="{
          ratio: 1.5,
          minFontSize: '10px',
          maxFontSize: '14px',
          delay: 100,
        }"
      >
        &nbsp;0.00 %
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "VoteLineBarChoiceResult",
  props: ["vote", "choice", "color"],
};
</script>
